<template>
  <basic-container>
    <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form"
      :permission="permissionList" :before-open="beforeOpen" :before-close="beforeClose" @row-del="rowDel"
      @row-update="rowUpdate" @row-save="rowSave" @search-change="searchChange" @search-reset="searchReset"
      @selection-change="selectionChange" @current-change="currentChange" @size-change="sizeChange"
      @refresh-change="refreshChange" @on-load="onLoad" @tree-load="treeLoad">
      <template slot="menuLeft">
        <el-button type="danger" size="small" icon="el-icon-delete" v-if="permission.dept_delete" plain
          @click="handleDelete">删 除
        </el-button>
      </template>
      <!-- <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click.stop="handleAdd(scope.row,scope.index)"
          v-if="userInfo.role_name.includes('admin')"
        >新增子项
        </el-button>
      </template> -->
      <template slot-scope="{ row }" slot="deptCategory">
        <el-tag>{{ row.deptCategoryName }}</el-tag>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import {
  getLazyList,
  getChildList,
  remove,
  update,
  add,
  getDept,
  getDeptTree,
  getUser,
} from "@/api/system/dept";
import { mapGetters } from "vuex";
// import website from "@/config/website";

export default {
  name: 'team-manager',
  data() {
    return {
      form: {},
      selectionList: [],
      query: {},
      loading: true,
      parentId: 0,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      option: {
        height:'auto',
        calcHeight: 10,
        lazy: true,
        tip: false,
        simplePage: true,
        searchShow: true,
        searchMenuSpan: 6,
        tree: true,
        border: true,
        index: true,
        selection: true,
        viewBtn: true,
        menuWidth: 300,
        dialogClickModal: false,
        column: [
          {
            label: "上级组织",
            prop: "parentId",
            hide: true,
            rules: [
              {
                required: false,
                message: "请选择上级组织",
                trigger: "blur",
              },
            ],
            type: "tree",
            dicData: [],
            props: {
              label: "title",
              value: "id",
            },
          },
          {
            label: "团队名称",
            prop: "deptName",
            rules: [
              {
                required: true,
                message: "请输入团队名称",
                trigger: "blur",
              },
            ],
          },

          {
            label: "团队编号",
            prop: "id",
            addDisplay: false,
            // viewDisplay: false,
            editDisplay: false,
          },
          {
            label: "团队类型",
            type: "select",
            dicUrl: "/api/blade-system/dict/dictionary?code=org_category",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            slot: true,
            prop: "deptCategory",
            rules: [
              {
                required: true,
                message: "请选择团队类型",
                trigger: "blur",
              },
            ],
          },
          {
            label: "成员数量",
            prop: "memberCount",
            type: "number",
            addDisplay: false,
            viewDisplay: true,
            editDisplay: false,
            // precision: "0",
            // step: "1",
            // min: "0",
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入成员数量",
            //     trigger: "click",
            //   },
            // ],
          },
          {
            label: "负责人员",
            prop: "managerName",
            addDisplay: false,
            viewDisplay: false,
            editDisplay: false,
          },
          {
            label: "负责人员",
            prop: "managerId",
            hide: true,
            rules: [
              {
                // required: true,
                message: "请选择负责人员",
                trigger: "blur",
              },
            ],
            type: "select",
            dicData: [],
          },
          {
            label: "团队描述",
            prop: "remark",
            rules: [
              {
                required: false,
                message: "请输入团队描述",
                trigger: "click",
              },
            ],
          },
          {
            label: "更新时间",
            prop: "updateTime",
            addDisplay: false,
            viewDisplay: true,
            editDisplay: false,
          },
        ],
      },
      data: [],
    };
  },
  mounted() {
    this.getUsers();
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  methods: {
    getUsers() {
      getUser().then((res) => {
        let dicData = [];
        if (res.data.code === 200) {
          const data = res.data.data;
          data.map((item) => {
            dicData.push({
              label: item.realName,
              value: item.id,
            });
          });
          this.$nextTick(() => {
            let column = this.findObject(this.option.column, "managerId");
            column.dicData = JSON.parse(JSON.stringify(dicData));
          });
        }
      });
    },
    initData() {
      getDeptTree().then((res) => {
        let column = this.findObject(this.option.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    handleAdd(row) {
      this.parentId = row.id;
      let column = this.findObject(this.option.column, "parentId");
      column.value = row.id;
      column.addDisabled = true;
      this.$refs.crud.rowAdd();
    },
    rowSave(row, done, loading) {
      add(row).then(
        (res) => {
          // 获取新增数据的相关字段
          const data = res.data.data;
          row.id = data.id;
          row.deptCategoryName = data.deptCategoryName;
          row.tenantId = data.tenantId;
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.refreshChange();
          // 数据回调进行刷新
          // done(row);
          done();
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      update(row).then(
        () => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.refreshChange();
          // 数据回调进行刷新
          done(row);
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    rowDel(row, index, done) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          // 数据回调进行刷新
          // done(row);
          this.onLoad();
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;
          this.$refs.crud.refreshTable();
          this.$refs.crud.toggleSelection();
          // 表格数据重载
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    searchReset() {
      this.query = {};
      this.parentId = 0;
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.parentId = "";
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    beforeOpen(done, type) {
      if (["add", "edit"].includes(type)) {
        this.initData();
      }
      if (["edit", "view"].includes(type)) {
        getDept(this.form.id).then((res) => {
          this.form = res.data.data;
        });
      }
      done();
    },
    beforeClose(done) {
      this.parentId = "";
      // const column = this.findObject(this.option.column, "parentId");
      // column.value = "";
      // column.addDisabled = false;
      done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad();
    },
    onLoad(params = {}) {
      this.data = []
      this.loading = true;
      this.data = []
      getLazyList(0).then(
        (res) => {
          this.data = res.data.data.records;
          this.loading = false;
          this.selectionClear();
        }
      );
    },
    treeLoad(tree, treeNode, resolve) {
      const parentId = tree.id;
      getChildList(parentId).then((res) => {
        resolve(res.data.data);
      });
    },
  },
};
</script>

<style>

</style>
